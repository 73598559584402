<!-- Login.vue -->

<template>
  <div class="login-container">
    <el-card class="login-card">
      <div slot="header" class="login-header" >
        <h2>湖北省建筑劳务协会</h2>
      </div>
      <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="100px" class="login-form">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名" ref="usernameInput" @keydown.enter="focusPassword"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" ref="passwordInput" @keydown.enter="submitForm"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%; padding: 10px 30px;" @click="submitForm">登录</el-button>
        </el-form-item>
      </el-form>
      <el-alert v-if="loginFailed" show-icon closable :title="loginErrorMessage" type="error" @close="loginFailed = false" center></el-alert>
    </el-card>
  </div>
</template>

<script>
// import { login } from '@/api/user';

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      loginFailed: false,
      loginErrorMessage: ''
    };
  },
  mounted() {

    this.$nextTick(() => {
      // 页面加载完毕后，将焦点定位到用户名输入框
      this.$refs.usernameInput.focus();
    });
    // 打印 store 对象
    console.log('Vue instance:', this);
  },
  methods: {
    async submitForm() {
      try {
        await this.$api.user.login(this.loginForm);
        // 登录成功后，跳转到首页或其他页面
        this.$router.push('/');
      } catch (error) {
        this.loginFailed = true;
        this.loginErrorMessage = '账号或密码不正确，请重新输入。';
        console.error('Login failed:', error);
      }
    },
    focusPassword() {
      // 当用户在用户名输入框按下回车键时，将焦点定位到密码输入框
      this.$refs.passwordInput.focus();
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 500px;
}

.login-header {
  text-align: center;
  color: dodgerblue;
}

.login-form {
  margin-top: 30px;
}

.login-button {
  width: 100%;
  text-align: center;
}
</style>
