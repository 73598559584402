import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import WokerView from "@/views/Woker.vue";
import Layout from "@/layout/Layout.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Company from "@/views/Company.vue";
import About from "@/views/About.vue";
import store from "@/store";


const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/company',
        name: 'company',
        component: Company
      },
      {
        path: '/worker',
        name: 'worker',
        component: WokerView
      },
      {
        path: '/about',
        name: 'about',
        component: About
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



// 全局前置守卫
// 全局前置守卫
router.beforeEach((to, from, next) => {
  // const isLoggedIn = store.state.accessToken; // 使用Vuex store中的状态
  const  isLoggedIn=store.state.isLoggedIn;
console.log("accessToken:",store.state.isLoggedIn)
  if (!store.state.isLoggedIn && to.path !== '/login') {
    next({ path: '/login', replace: true });
  } else {
    next();
  }
});

export default router
