<template>
  <div
      style="height: 50px; line-height: 50px; background-color: rgb(36,47,66);display: flex; align-items: center;">
    <div style="margin-left: 15px; display: flex; justify-content: center; align-items: center;">
      <img src="favicon.ico" style="height: 30px; width: auto; color-rendering: auto">
    </div>
    <div style="width: 200px;padding-left: 10px;font-weight: bold;color: white">
      湖北省建筑劳务协会
    </div>
    <div style="flex: 1"></div>
    <div style="width:150px;height: 50px; display: flex;align-items: center;justify-content: center">
      <el-dropdown>
        <span class="el-dropdown-link" style="color: white">
          {{ realName }}
          <el-icon class="el-icon--right">
            <ArrowDown/>
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>个人信息</el-dropdown-item>
            <el-dropdown-item @click="handleLogout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { ArrowDown } from "@element-plus/icons";
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['realName']) // 从Vuex store中映射realName
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
/* 样式 */
</style>
