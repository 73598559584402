<template>
  <div class="worker" style="padding: 10px">
    <div style="margin:10px 0;display: flex;justify-content: space-between;align-items: center">
      <div>

      </div>
      <div>
        <!-- <el-button type="primary" @click="addWorker">新增</el-button> -->
        <!-- <el-button type="primary">编辑</el-button> -->
        <!-- <el-button type="primary">导出</el-button> -->
        <el-input v-model="keyword" style="width: 240px" placeholder="请输入关键字"/>
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="index" label="编号" width="60" />
      <el-table-column prop="name" label="姓名" width="80" />
      <el-table-column prop="mobile" label="手机号"  width="120"/>
      <el-table-column prop="idcard" label="身份证号"  width="180" />
      <el-table-column prop="address" label="住址"  />
      <el-table-column prop="createTime" label="注册时间" :formatter="formatterDate"  />
      <!--      <el-table-column label="操作">-->
      <!--        <template #default="scope">-->
      <!--          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <div style="margin: 10px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="提示" v-model="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="form" label-width="120px">
        <el-form-item label="姓名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate, formatDateTime } from '@/utils/dateUtil.js';
import {searchWorker} from "@/api/worker";

export default {
  name: 'WorkerView',
  data() {
    return {
      keyword: "",
      dialogVisible: false,
      form: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {

    formatterDate(row, column) {
      return formatDateTime(row[column.property]);
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2); // 月份是从0开始的
      const day = ('0' + d.getDate()).slice(-2);
      // 格式化为 年-月-日
      return `${year}-${month}-${day}`;
    },
    fetchData() {
      this.$api.worker.fetchWorkerList(this.currentPage - 1, this.pageSize).then(res => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      }).catch(err => {
        console.log('Fetching data failed:', err);
      });
    },
    handleEdit() {},
    handleClose() {},
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1; // Reset to first page when size changes
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    search() {
      this.$api.worker.search(this.keyword, 0, 10).then(res => {
        this.tableData = res.data;
        this.total = res.data.length;
        this.currentPage = 1;
        this.pageSize = 10;
      }).catch(err => {
        console.log('Searching worker failed:', err);
      });
    }
  }
};
</script>

<style scoped>
/* 样式 */
</style>
