<template>
  <div class="home">
    <div class="stats">
      <div class="stat" v-for="(value, label) in stats" :key="label">
        <h3>{{ label }}</h3>
        <p>{{ value }}</p>
      </div>
    </div>
    <div class="chart-wrapper">
      <LineChart :chartData="chartData" :options="chartOptions"></LineChart>
    </div>
  </div>
</template>

<script>
import LineChart from '../components/LineChart.vue';

export default {
  name: 'Home',
  components: {
    LineChart,
  },
  data() {
    return {
      stats: {
        '会员单位': 0,
        '工人总数': 0,
        '本月新增': 0,
        '今日新增': 0,
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: '最近一个月用户增长',
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: '',
            pointBackgroundColor: 'rgba(255,165,0,1)', // 修改为橙色
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,165,0,1)', // 修改为橙色
            data: [],
            borderWidth: 3, // 加粗线条
            pointRadius: 6, // 增大数据点
            pointHoverRadius: 8, // 增大悬停时的数据点
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: '日期',
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: '数量',
            },
            beginAtZero: true,
          },
        },
      },
    };
  },
  methods: {
    async fetchStats() {
      try {
        const response = await this.$api.worker.getHomeStats();
        const data = response.data;
        this.stats['会员单位'] = data['会员单位'];
        this.stats['工人总数'] = data['工人总数'];
        this.stats['本月新增'] = data['本月新增'];
        this.stats['今日新增'] = data['今日新增'];
      } catch (error) {
        console.error('Error fetching home stats:', error);
      }
    },
    async fetchDailyNewWorkers() {
      try {
        const response = await this.$api.worker.getDailyNewWorkers();
        const labels = Object.keys(response.data);
        const data = Object.values(response.data);

        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100);
        gradient.addColorStop(0, 'rgba(108, 255, 208, 1)'); // 渐变的起始颜色：青色
        gradient.addColorStop(1, 'rgba(112, 154, 255, 1)'); // 渐变的结束颜色：紫色

        // 重新创建 chartData 对象
        this.chartData = {
          labels,
          datasets: [
            {
              label: '最近30天用户增长',
              backgroundColor: 'rgba(211,99,255,1)',
              borderColor: gradient, // 应用渐变颜色
              pointBackgroundColor: 'rgba(255,165,0,1)', // 修改为橙色
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(211,99,255,1)', // 修改为橙色
              data,
              borderWidth: 5, // 加粗线条
              pointRadius: 8, // 增大数据点
              pointHoverRadius: 8, // 增大悬停时的数据点
              tension: 0.5, // 平滑曲线
            },
          ],
        };
      } catch (error) {
        console.error('Error fetching daily new workers:', error);
      }
    },
  },
  async mounted() {
    await this.fetchStats();
    await this.fetchDailyNewWorkers();
  },
};
</script>

<style scoped>
.home {
  padding: 20px;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
}

.stat {
  background: #f5f5f5;
  /*background: rgb(113,154,255,0.6);*/
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 23%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.stat p {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.chart-wrapper {
  margin-top: 30px; /* 将图表向下移动 */
  height: 350px; /* 设置图表容器的高度 */
}
</style>
