import request from '@/utils/request';

export function fetchWorkerList(page, size) {
    return request({
        url: '/worker/list',
        method: 'get',
        params: { page, size }
    });
}

export function searchWorker(keyword) {
    return request({
        url: '/worker/search',
        method: 'get',
        params: { keyword }
    });
}

export function getHomeStats() {
    return request({
        url: '/home/stats',
        method: 'get'
    });
}

export function getDailyNewWorkers() {
    return request({
        url: '/home/dailynew',
        method: 'get'
    });
}

// 其他 worker 相关的 API 方法
