import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('accessToken'), // 根据 localStorage 设置初始状态
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    realName: localStorage.getItem('realName') || null,
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
      state.isLoggedIn = true;
      localStorage.setItem('accessToken', token); // 保存到 localStorage
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
      localStorage.setItem('refreshToken', token); // 保存到 localStorage
    },
    setRealName(state, name) {
      state.realName = name;
      localStorage.setItem('realName', name); // 保存到 localStorage
    },
    clearUserData(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.realName = null;
      state.isLoggedIn = false;
      localStorage.removeItem('accessToken'); // 从 localStorage 中移除
      localStorage.removeItem('refreshToken'); // 从 localStorage 中移除
      localStorage.removeItem('realName'); // 从 localStorage 中移除
    },
  },
  actions: {
    login({ commit }, userData) {
      commit('setAccessToken', userData.accessToken);
      commit('setRefreshToken', userData.refreshToken);
      commit('setRealName', userData.realName);
    },
    logout({ commit }) {
      commit('clearUserData');
    },
  },
  modules: {}
})
