import request from '@/utils/request';

export function fetchCompanyList(page, size) {
    return request({
        url: '/company/list',
        method: 'get',
        params: { page, size }
    });
}

export function addCompany(data) {
    return request({
        url: '/company/add',
        method: 'post',
        data
    });
}

export function deleteCompany(id) {
    return request({
        url: `/company/${id}`,
        method: 'delete'
    });
}

// export function searchCompany(keyword) {
//     return request({
//         url: '/company/search',
//         method: 'get',
//         params: { keyword }
//     });

export function searchCompany(page, size, keyword) { // 修改此处，添加page和size参数
    return request({
        url: '/company/search',
        method: 'get',
        params: { page, size, keyword } // 确保参数名称与后端接口匹配
    });

}
