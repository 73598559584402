<template>
  <div class="company" style="padding: 10px">
    <div style="margin: 10px 0; display: flex; justify-content: space-between; align-items: center">
      <div>
        <el-button v-if="!isSearchActive" type="primary" @click="addCompany">添加</el-button>
        <el-button v-if="isSearchActive" @click="resetSearch">返回</el-button>
      </div>
      <div>
        <el-input v-model="search" style="width: 240px" placeholder="请输入关键字"/>
        <el-button type="primary" @click="searchCompany">查询</el-button>
      </div>
    </div>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="memberId" label="编号" width="60"/>
      <el-table-column prop="companyName" label="单位名称" width="240"/>
      <el-table-column prop="major" label="负责人" width="80"/>
      <el-table-column prop="memberType" label="会员分类" width="100"/>
      <el-table-column prop="mainBussiness" label="主营业务" width="620">
        <template #default="scope">
          <div class="multi-line-ellipsis">{{ scope.row.mainBussiness }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确定删除吗?" @confirm="handleDelete(scope.row)">
            <template #reference>
              <el-button size="small" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        title="添加会员单位"
        v-model="dialogVisible"
        width="45%"
        :before-close="handleClose"
        :modal="true"
        :modal-append-to-body="true"
        :close-on-click-modal="false">
      <el-form :model="form" label-width="100px">
        <el-form-item label="编号">
          <el-input v-model="form.memberId"></el-input>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="会员类型">
          <el-input v-model="form.memberType"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-input v-model="form.major"></el-input>
        </el-form-item>
        <el-form-item label="介绍网址">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="主营业务">
          <el-input type="textarea" v-model="form.mainBussiness" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCompany">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/dateUtil.js';
import { fetchCompanyList, addCompany, deleteCompany, searchCompany } from '@/api/company';

export default {
  name: 'Company',
  data() {
    return {
      search: "",
      dialogVisible: false,
      form: {
        memberId: '',
        companyName: '',
        memberType: '',
        major: '',
        url: '',
        mainBussiness: ''
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      initialData: [],
      initialTotal: 0,
      initialPage: 1,
      initialPageSize: 10,
      isSearchActive: false
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    addCompany() {
      this.form = {
        memberId: '',
        companyName: '',
        memberType: '',
        major: '',
        url: '',
        mainBussiness: ''
      };
      this.dialogVisible = true;
    },
    saveCompany() {
      addCompany(this.form).then(() => {
        this.dialogVisible = false;
        this.fetchData();
      }).catch(err => {
        console.log('Saving company failed:', err);
      });
    },
    formatterDate(row, column) {
      return formatDate(row[column.property]);
    },
    fetchData() {
      fetchCompanyList(this.currentPage - 1, this.pageSize).then(res => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
        this.initialData = res.data.content;
        this.initialTotal = res.data.totalElements;
        this.initialPage = this.currentPage;
        this.initialPageSize = this.pageSize;
        this.isSearchActive = false;
      }).catch(err => {
        console.log('Fetching data failed:', err);
      });
    },
    handleEdit(row) {
      this.form = { ...row };
      this.dialogVisible = true;
    },
    handleDelete(row) {
      deleteCompany(row.id).then(() => {
        this.fetchData();
      }).catch(err => {
        console.log('Deleting company failed:', err);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    searchCompany() {
      searchCompany(this.currentPage - 1, this.pageSize, this.search).then(res => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
        this.isSearchActive = true;
      }).catch(err => {
        console.log('Searching company failed:', err);
      });
    },
    resetSearch() {
      this.search = "";
      this.tableData = this.initialData;
      this.total = this.initialTotal;
      this.currentPage = this.initialPage;
      this.pageSize = this.initialPageSize;
      this.isSearchActive = false;
    }
  }
}
</script>

<style scoped>
.company {
  padding: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.multi-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
