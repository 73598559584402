// utils/dateUtil.js

/**
 * 格式化完整的日期时间
 * @param {Date|string} dateTime 需要格式化的日期或日期时间字符串
 * @returns {string} 返回格式化的日期时间字符串
 */
export function formatDateTime(dateTime) {
    const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 仅格式化日期
 * @param {Date|string} dateTime 需要格式化的日期或日期时间字符串
 * @returns {string} 返回格式化的日期字符串
 */
export function formatDate(dateTime) {
    const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}