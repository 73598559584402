<template>
  <div >
    <el-menu
        style="width:150px;min-height:calc(100vh - 50px);" default-active="user" :default-openeds="['1']"
        router
        class="el-menu-vertical-demo"
        active-text-color="#ffd04b"
        background-color="rgb(50,65,87)"
        text-color="#fff">

      <el-menu-item index="home" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-home"></i>
        </div>
        <span>首页</span>
      </el-menu-item>

      <el-menu-item index="company" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-company"></i>
        </div>
        <span>会员单位</span>
      </el-menu-item>

      <el-menu-item index="worker" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-user"></i>
        </div>
        <span>注册用户</span>
      </el-menu-item>

      <el-menu-item index="about" class="menu-item">
        <div class="icon-container">
          <i class="iconfont icon-user"></i>
        </div>
        <span>关于我们</span>
      </el-menu-item>

    </el-menu>
  </div>
</template>

<style scoped>
.menu-item {
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* 根据需要调整间距 */
  font-size: 32px; /* 调整图标大小 */
}
</style>

<script>
export default {
  name: "Aside"
}
</script>
