import request from '../utils/request';
import store from '../store'; // 引入store

// 登录函数
export const login = async ({ username, password }) => {
    try {
        const response = await request({
            method: 'POST',
            url: '/auth/weblogin',
            data: { username, password }
        });
        console.log("user.js==", response);

        const { accessToken, refreshToken, realName } = response.data;

        // 将数据存到store
        store.dispatch('login', { accessToken, refreshToken, realName });

        return true; // 返回登录成功标志
    } catch (error) {
        console.error('Login failed:', error);
        throw error; // 抛出错误，让调用者处理登录失败逻辑
    }
};

// 登出函数
export const logout = async () => {
    try {
        const response = await request({
            method: 'POST',
            url: '/auth/logout'
        });

        // 方式2 登出成功，清除 Vuex store 中的用户信息
        store.dispatch('logout');

        return true; // 返回登出成功标志
    } catch (error) {
        console.error('Logout failed:', error);
        throw error; // 抛出错误，让调用者处理登出失败逻辑
    }
};
